import axios, { AxiosError } from "axios";
import { DischargeBody, Methods, PostApiProp, TAdmission, TApiSingleResponse, TBilling, TDesignation, TDoctor, TEmployee, TLogin, TMedicine, TPatient, TPatientFilePayload, TPaymentPayload, TPaymentUpdate, TProfielPayload, TQueryParams, TUser } from "../types/common";

let fileURL = "";
let fileAuth = "";

if (process.env.NODE_ENV === "development") {
    // axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1";
    // axios.defaults.baseURL = "http://192.168.31.13:8000/api/v1";
    axios.defaults.baseURL = "http://192.168.1.2:8000/api/v1";
    // fileURL = "http://127.l.0.1:4500/api/v1/upload?project=hyb";
    fileAuth = "461584056acb8bd44632c30f58797171efc583c4f6dd0485b979e15a515aa1ab";
} else {
    axios.defaults.baseURL = "https://ningthoujamhealthcare.in/api/v1";
    fileURL = "https://skynet.codexp.in/api/v1/upload?project=ningthoujamhealthcare";
    fileAuth = "29acb3b424898e07019dd4a3d50ca4faecc464218e9696ccdc37a165209b33a1";
}

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

export const setAuthHeader = (token: string | undefined) => {
    if (token) {
        axios.defaults.headers["Authorization"] = token;
        return true;
    }
};

// setAuthHeader("52a0acd509df99c8c59671e4");

export const clearAuthHeader = () => {
    axios.defaults.headers["Authorization"] = null;
    return true;
};

if (process.env.NODE_ENV === 'development') {
    fileURL = "http://192.168.1.12:4500/api/v1/upload?project=ningthoujamhealthcare";
    // fileURL = "http://127.0.0.1:4500/api/v1/upload?project=ningthoujamhealthcare";
} else {
    fileURL = "https://skynet.codexp.in/api/v1/upload?project=ningthoujamhealthcare";
}

/* FILE API */
export const uploadFile = (form: any) => axios.post(
    `${fileURL}`, form,
    {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${fileAuth}`
        }
    }
);

export const skynet = process.env.NODE_ENV === "development" ? "http://localhost:4500/ningthoujamhealthcare" : "https://skynet.codexp.in/ningthoujamhealthcare";


/* USER APIs */
export const getMetaData = () => _callApi(`/metadata`, "get");


// AUTH
export const login = (body: TLogin) => _callApi(`/login`, "post", body)
export const logoutAPI = (): Promise<TApiSingleResponse> => _callApi(`/logout`, "get")
export const getProfile = () => _callApi(`/profile`, "get")
export const updateProfile = (body: TProfielPayload) => _callApi(`/profile/update`, "put", body)
export const updateProfilePwd = (body: TProfielPayload) => _callApi(`/profile/update/pwd`, "post", body)

export const getFilesByPatientId = ({ id }: TQueryParams) => _callApi(`/patients/files/${id}`, "get");
export const deletePatientFiles = (id: number) => _callApi(`/patients/files/${id}`, "delete");
export const updatePatientFiles = ({ body, id }: PostApiProp) => _callApi(`/patients/files/upload/${id}`, "put", body);
export const addPatientFiles = (body: TPatientFilePayload) => _callApi(`/patients/files/upload`, "post", body);

export const addUser = (body: TUser) => _callApi(`/users`, "post", body)


export const getDashboardData = ({ month, year }: TQueryParams) => _callApi(`/dashboard?month=${month || ""}&year=${year || ""}`, "get");
export const getUsers = ({ id, offset, limit, name, email, mobile }: TQueryParams) => _callApi(`/users?id=${id || 0}&offset=${offset}&limit=${limit}&name=${name || ""}&email=${email || ""}&mobile=${mobile || 0}`, "get");
export const getUserById = (id: number) => _callApi(`/users/${id}`, "get");
export const deleteUser = (id: number) => _callApi(`/users/${id}`, "delete");
export const updateUser = ({ body, id }: PostApiProp) => _callApi(`/users/${id}`, "put", body);
export const updatePasswordForUser = ({ body, id }: PostApiProp) => _callApi(`/users/update/${id}`, "put", body);

// DESIGNATION
export const getAllPayment = ({
    offset,
    limit,
    id,
    name,
    patient,
    bill,
    admission,
    startDate,
    endDate
}: TQueryParams) => {
    return _callApi(
        `/payments?offset=${offset}` +
        `&limit=${limit}` +
        `&id=${id || ""}` +
        `&name=${name || ""}` +
        `&patient=${patient || ""}` +
        `&bill=${bill || ""}` +
        `&admission=${admission || ""}` +
        `&startDate=${startDate || ""}` +
        `&endDate=${endDate || ""}`,
        "get"
    );
};

export const addPayment = (body: TPaymentPayload) => _callApi(`/payments`, "post", body)
export const getPaymentByBill = ({ billId }: TQueryParams) => _callApi(`/payments/bill/${billId || ""}`, "get");
export const updateBill = ({ id, body }: PostApiProp) => _callApi(`/payments/bill/${id}`, "put", body);
export const getPaymentByPatient = (patient: number) => _callApi(`/payments/patient/${patient}`, "get");
export const getPaymentByAdmission = (admissionId: number) => _callApi(`/payments/admission/${admissionId}`, "get");
export const deletePayment = (id: number) => _callApi(`/payments/${id}`, "delete");
export const updatePayment = ({ id, body }: PostApiProp) => _callApi(`/payments/${id}`, "put", body);




// DESIGNATION
export const addDesignation = (body: TDesignation) => _callApi(`/designations`, "post", body)
export const getDesignations = ({ offset, limit, id, name }: TQueryParams) => _callApi(`/designations?offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}`, "get");
export const getDesignationById = (id: number) => _callApi(`/designations/${id}`, "get");
export const deleteDesignation = (id: number) => _callApi(`/designations/${id}`, "delete");
export const updateDesignation = ({ id, body }: PostApiProp) => _callApi(`/designations/${id}`, "put", body);


/* PATIENT APIs */
export const addPatient = (body: TPatient) => _callApi(`/patients`, "post", body)
export const getPatients = ({ offset, limit, id, name, startDate, endDate }: TQueryParams) => _callApi(`/patients?offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}&startDate=${startDate || ""}&endDate=${endDate || ""}`, "get");
export const getPatientById = ({ id }: TQueryParams) => _callApi(`/patients/${id}`, "get");
export const getPatientBillings = ({ id, admission }: TQueryParams) => _callApi(`/billings/patient/${id}/${admission}`, "get");
export const getAllBillingsForPatient = ({ patient }: TQueryParams) => _callApi(`/billings/patient/${patient}`, "get");
export const deletePatient = (id: TQueryParams) => _callApi(`/patients/${id}`, "delete");
export const updatePatient = ({ body, id }: PostApiProp) => _callApi(`/patients/${id}`, "put", body);

/* VITALS APIs */
export const addVital = (body: TMedicine) => _callApi(`/vitals`, "post", body)
export const getVitals = ({ patient, offset, limit, id, name, startDate, endDate, inCharge }: TQueryParams) => _callApi(`/vitals?patient=${patient || ""}&offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}&startDate=${startDate || ""}&endDate=${endDate || ""}&in_charge=${inCharge || ""}`, "get");
export const getVitalById = (id: TQueryParams) => _callApi(`/vitals/${id}`, "get");
export const getVitalByPatientDate = ({ patient, startDate, endDate }: TQueryParams) => _callApi(`/vitals/vitalcharts/${patient}?startDate=${startDate || ""}&endDate=${endDate || ""}`, "get");
export const deleteVital = (id: TQueryParams) => _callApi(`/vitals/${id}`, "delete");
export const updateVital = ({ body, id }: PostApiProp) => _callApi(`/vitals/${id}`, "put", body);

/* MEDICINES APIs */
export const addMedicine = (body: TMedicine) => _callApi(`/medicines`, "post", body)
export const getMedicines = ({ offset, limit, name }: TQueryParams) => _callApi(`/medicines?offset=${offset}&limit=${limit}&name=${name || ""}`, "get");
export const getMedicineById = (id: number) => _callApi(`/medicines/${id}`, "get");
export const deleteMedicine = (id: number) => _callApi(`/medicines/${id}`, "delete");
export const updateMedicine = ({ body, id }: PostApiProp) => _callApi(`/medicines/${id}`, "put", body);

/* EMPLOYEE APIs */
export const addEmployee = (body: TEmployee) => _callApi(`/employees`, "post", body);
export const getEmployees = ({ offset, limit, id, name, mobile }: TQueryParams) => _callApi(`/employees?offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}&mobile=${mobile || 0}`, "get");
export const getEmployeeById = (id: number) => _callApi(`/employees/${id}`, "get");
export const deleteEmployee = (id: number) => _callApi(`/employees/${id}`, "delete");
export const updateEmployee = ({ body, id }: PostApiProp) => _callApi(`/employees/${id}`, "put", body);

/* DOCTORS APIs */
export const addDoctor = (body: TDoctor) => _callApi(`/doctors`, "post", body);
export const getDoctors = ({ offset, limit, id, name }: TQueryParams) => _callApi(`/doctors?offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}`, "get");
export const getDoctorById = (id: number) => _callApi(`/doctors/${id}`, "get");
export const deleteDoctor = (id: number) => _callApi(`/doctors/${id}`, "delete");
export const updateDoctor = ({ body, id }: PostApiProp) => _callApi(`/doctors/${id}`, "put", body);

export const addPartialPayment = (body: TPaymentUpdate) => _callApi(`/payment/bills`, "post", body);

/* DOCTORS APIs */
export const addTreatment = (body: TDoctor) => _callApi(`/treatments`, "post", body);
export const getTreatments = ({ offset, limit, id, name, patient, startDate, endDate }: TQueryParams) => _callApi(`/treatments?offset=${offset}&limit=${limit}&id=${id || 0}&name=${name || ""}&patient=${patient || 0}&startDate=${startDate || ""}&endDate=${endDate || ""}`, "get");
export const getTreatmentById = (id: number) => _callApi(`/treatments/${id}`, "get");
export const deleteTreatment = (id: number) => _callApi(`/treatments/${id}`, "delete");
export const updateTreatment = ({ body, id }: PostApiProp) => _callApi(`/treatments/${id}`, "put", body);


/* BILLINGS APIs */
export const getBillingSummary = ({ offset, limit, id, patient, name, status }: TQueryParams) => _callApi(`/billings/summary?offset=${offset}&limit=${limit}&id=${id || 0}&patient=${patient || 0}&name=${name || ""}&status=${status || ""}`, "get");
export const addBilling = (body: TBilling) => _callApi(`/billings`, "post", body);
export const getBillings = ({ offset, limit, id, patient }: TQueryParams) => _callApi(`/billings?offset=${offset}&limit=${limit}&id=${id || 0}&patient=${patient || 0}`, "get");
export const getBillingById = ({ id }: TQueryParams) => _callApi(`/billings/${id}`, "get");
export const getPayBillingById = ({ id }: TQueryParams) => _callApi(`/billings/pay/${id}`, "get");
export const getBillingByPatient = ({ patient }: TQueryParams) => _callApi(`/billings/patient/${patient}`, "get");
export const deleteBilling = (id: TQueryParams) => _callApi(`/billings/${id}`, "delete");
export const updateBilling = ({ body, id }: PostApiProp) => _callApi(`/billings/${id}`, "put", body);


/* ADMISSIONS APIs */
export const addAdmission = (body: TAdmission) => _callApi(`/admissions`, "post", body);
export const getAdmissions = ({ offset, limit, id, patient, name, startDate, endDate }: TQueryParams) => _callApi(`/admissions?offset=${offset}&limit=${limit}&name=${name || ""}&id=${id || 0}&patient=${patient || 0}&startDate=${startDate || ""}&endDate=${endDate || ""}`, "get");
export const getDischargedAdmissions = ({ offset, limit, id, patient, name, startDate, endDate }: TQueryParams) => _callApi(`/discharge/patients?offset=${offset}&limit=${limit}&name=${name || ""}&id=${id || 0}&patient=${patient || 0}&startDate=${startDate || ""}&endDate=${endDate || ""}`, "get");
export const getAdmissionById = (id: number) => _callApi(`/admissions/${id}`, "get");
export const deleteAdmission = (id: number) => _callApi(`/admissions/${id}`, "delete");
export const updateAdmission = ({ body, id }: PostApiProp) => _callApi(`/admissions/${id}`, "put", body);

// DISCHARGE
export const getDueByPatient = ({ admission }: TQueryParams) => _callApi(`/discharge/billings/${admission}`, "get");
export const dischargePatient = (body: DischargeBody) => _callApi(`/discharge`, "post", body);

const _callApi = async (url: string, method: Methods = "get", body = {}) => {
    try {
        const response = await axios[method](url, body);
        const { status, data } = response;
        if (status === 401) {
            // dispatch(logoutUser());
            return { success: false };
        } else if (status === 200 || status === 201) {
            return data;
        } else {
            return { success: false };
        }
    } catch (error) {
        const err = error as AxiosError;
        return err.response?.data;
    }
};
