import React, { useEffect, useState } from "react";
import {
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Backdrop,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "../../services/helper";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 620,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};

interface DoctorRow {
  id: number;
  created_on: string;
  name: string;
}
interface FormValues {
  id: number;
  created_on: string;
  name: string;
  mobile: string;
  age: string;
  marital_status: string;
  diagnosis: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  remark: string;
  gender: string;
  is_discharged: number;
  referred_by: number | null;
  files: any[];
}
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type EditPatientProps = {
  open: boolean;
  ptId: number | null;
  onClose: () => void;
};

const EditPatient = ({ open, onClose, ptId }: EditPatientProps) => {
  const queryClient = useQueryClient();
  const { mutationFn, invalidateKey } = queryConfigs.updatePatient;
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  const { queryKey: doctors, queryFn: doctorFunc } = queryConfigs.getDoctors;
  const { queryKey: ptKey, queryFn: getPtFn } = queryConfigs.getPatientById;
  const { data: ptData, isLoading } = useGetQuery({
    key: ptKey,
    func: getPtFn,
    params: {
      id: ptId,
    },
    isEnabled: ptId ? true : false,
  });
  const { data: doctorData } = useGetQuery({
    key: doctors,
    func: doctorFunc,
  });

  const { mutate: updatePatient } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Patient Info update successfully");
      onClose();
    },
    client: queryClient,
  });

  const [formValues, setFormValues] = useState<FormValues | null>(null);

  useEffect(() => {
    if (!isLoading && ptData?.data) {
      setFormValues(ptData.data as FormValues);
    }
  }, [ptData, isLoading]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevState) => {
      if (!prevState) return prevState;

      return {
        ...prevState,
        [name]:
          name === "admission_charge" || name === "referred_by"
            ? parseInt(value, 10) || 0
            : value,
      };
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setFormValues((prevState) => {
      if (!prevState) return prevState;

      return { ...prevState, [name]: value };
    });
  };

  const handleReferredByChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as unknown as string;

    setFormValues((prevState) => {
      if (!prevState) return prevState;

      return {
        ...prevState,
        referred_by: value ? parseInt(value, 10) : null,
      };
    });
  };

  const handleSubmit = () => {
    updatePatient({ body: formValues, id: formValues?.id });
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      {!isLoading ? (
        <Box sx={style}>
          <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
            <DialogTitle
              sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
            >
              Edit Details
            </DialogTitle>
            <IconButton onClick={onClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </div>

          <section className="space-y-4 p-5">
            <TextField
              required
              name="name"
              label="Name"
              value={formValues?.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
            />
            <div className="flex justify-between space-x-4">
              <TextField
                required
                name="mobile"
                label="Mobile"
                value={formValues?.mobile || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
              />
              <TextField
                required
                name="emergency_contact"
                label="Emergency Contact"
                value={formValues?.emergency_contact || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
              />
            </div>
            <div className="flex justify-between space-x-2 items-center">
              <TextField
                required
                name="age"
                label="Age"
                value={formValues?.age || ""}
                onChange={handleInputChange}
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 3,
                  },
                  classes: {
                    root: "text-black",
                    input: "h-10 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black font-Poppins",
                }}
                sx={{
                  width: "30%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
              />
              <FormControl fullWidth margin="dense" variant="outlined" required>
                <InputLabel
                  style={{
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  Marital Status
                </InputLabel>
                <Select
                  name="marital_status"
                  value={formValues?.marital_status || ""}
                  onChange={handleSelectChange}
                  label="Marital Status"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "1.5rem",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "inherit",
                      borderRadius: "1.5rem",
                    },
                    "& .MuiInputBase-input": {
                      height: "1rem",
                      padding: "1rem 1rem",
                    },
                    "& .MuiSelect-icon": {
                      color: "gray",
                    },
                  }}
                >
                  <MenuItem value="unmarried">Unmarried</MenuItem>
                  <MenuItem value="married">Married</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="dense" variant="outlined" required>
                <InputLabel
                  style={{
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  Gender
                </InputLabel>
                <Select
                  name="gender"
                  value={formValues?.gender || ""}
                  onChange={handleSelectChange}
                  label="Gender"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "1.5rem",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "inherit",
                      borderRadius: "1.5rem",
                    },
                    "& .MuiInputBase-input": {
                      height: "1rem",
                      padding: "1rem 1rem",
                    },
                    "& .MuiSelect-icon": {
                      color: "gray",
                    },
                  }}
                  className="rounded-3xl bg-white text-black"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              required
              name="address"
              label="Address"
              value={formValues?.address || ""}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
            <TextField
              required
              name="district"
              label="District"
              value={formValues?.district || ""}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />

            <div className="flex justify-between space-x-5 items-center">
              <TextField
                required
                name="pincode"
                label="Pincode"
                value={formValues?.pincode || ""}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "text-black",
                    input: "h-8 py-0",
                  },
                }}
                inputProps={{
                  className: "text-black",
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit",
                  },
                  "& .MuiInputBase-input": {
                    height: "1rem",
                    padding: "1rem 1rem",
                  },
                }}
                className="rounded-3xl bg-white"
              />
            </div>

            <div>
              <FormControl
                required
                variant="outlined"
                fullWidth
                sx={{ marginTop: 1 }}
              >
                <InputLabel
                  style={{
                    color: "gray",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  Referred By
                </InputLabel>
                <Select
                  value={formValues?.referred_by ?? ""}
                  onChange={handleReferredByChange}
                  label="Prescriber"
                  sx={{
                    width: "40%",
                    marginBottom: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "1.5rem",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "inherit",
                      borderRadius: "1.5rem",
                    },
                    "& .MuiInputBase-input": {
                      height: "1rem",
                      padding: "1rem 1rem",
                      textTransform: "capitalize",
                    },
                    "& .MuiSelect-icon": {
                      color: "gray",
                    },
                  }}
                >
                  {doctorData?.list?.map((doctor: DoctorRow) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TextField
              required
              name="diagnosis"
              label="Diagnosis"
              value={formValues?.diagnosis}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
            <TextField
              required
              name="remark"
              label="Remark"
              value={formValues?.remark}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              rows={3}
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-8 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
          </section>

          <div className="flex justify-center mb-4 items-center">
            <DialogActions>
              <div className="mr-12">
                <button
                  onClick={handleSubmit}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Update
                  </span>
                  <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
              </div>
              <div className="ml-12">
                <button
                  onClick={onClose}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Cancel
                  </span>
                  <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
              </div>
            </DialogActions>
          </div>
        </Box>
      ) : (
        <Box sx={style}>
          <Typography>NO DATA AVAILABLE</Typography>
        </Box>
      )}
    </Modal>
  );
};

export default EditPatient;
