import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Backdrop,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import { TPaymentPayload } from "../../types/common";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type AddPaymentProps = {
  open: boolean;
  onClose: () => void;
  billID: number | null;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
type BillingData = {
  id?: number | null;
  patient?: number | null;
  admission?: number | null;
  patient_name?: string | null;
  address?: string | null;
  organisation?: number | null;
  created_on?: string | null;
  dues?: number | null;
  deducted_amount?: number | null;
  extra_charge?: number | null;
  remarks?: string | null;
  paid_amount?: number | null;
  month?: number | null;
  year?: number | null;
  admission_fee?: number | null;
  admission_date?: string | null;
};
type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};
const AddPayment = ({ open, onClose, billID }: AddPaymentProps) => {
  const [enableEdit, setEnableEdit] = useState(true);
  const [level, setLevel] = useState<number>(0);
  const [pay, setPay] = useState<TPaymentPayload>({
    id: null,
    bill: null,
    patient: null,
    admission: null,
    received_by: null,
    remark: "",
    month: null,
    year: null,
    upi_amount: 0,
    cash_amount: 0,
    card_amount: 0,
  });
  const [payment, setPayment] = useState<BillingData>({
    id: null,
    patient: null,
    admission: null,
    patient_name: "",
    dues: 0,
    deducted_amount: 0,
    extra_charge: 0,
    remarks: "",
    paid_amount: 0,
    month: null,
    year: null,
    admission_fee: 0,
    admission_date: "",
  });
  const [totalPayable, setTotalPayable] = useState<number>(0);
  const [totalAmt, setTotalAmt] = useState<number>(0);

  const { queryFn, queryKey } = queryConfigs.getPayBillingById;
  const { queryFn: payFunc, queryKey: payKey } = queryConfigs.addPayment;
  const { invalidateKey, mutationFn } = queryConfigs.updateBill;
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const {
    data: billData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: { id: billID },
    isEnabled: !!billID,
  });

  const { mutate: updateBill } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    invalidateKeys: [
      ["billings"],
      ["billing"],
      ["billings-summary"],
      ["dashboard"],
    ],
    onSuccess: () => {
      showNotification("success", "Bill updated successfully");
      setEnableEdit(true);
      refetch();
    },
  });

  const { mutate: addPayment } = useMutationQuery({
    key: payKey,
    func: payFunc,
    invalidateKeys: [
      ["billings"],
      ["billing"],
      ["billings-summary"],
      ["dashboard"],
    ],
    onSuccess: () => {
      showNotification("success", "Payment Added successfully");
      handleCancel();
      setLevel(0);

      onClose();
    },
  });

  const { data: employeeData, isLoading: isEmployeeLoading } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  useEffect(() => {
    if (billData && billData.data) {
      setPayment((prevPayment) => ({
        ...prevPayment,
        id: billData.data.id ?? prevPayment.id,
        patient: billData.data.patient ?? prevPayment.patient,
        admission: billData.data.admission ?? prevPayment.admission,
        patient_name: billData.data.patient_name ?? prevPayment.patient_name,
        dues: billData.data.dues ?? prevPayment.dues,
        deducted_amount: 0,
        extra_charge: 0,
        // deducted_amount:
        //   billData.data.deducted_amount ?? prevPayment.deducted_amount,
        // extra_charge: billData.data.extra_charge ?? prevPayment.extra_charge,
        remarks: billData.data.remarks ?? prevPayment.remarks,
        paid_amount: billData.data.paid_amount ?? prevPayment.paid_amount,
        month: billData.data.month ?? prevPayment.month,
        year: billData.data.year ?? prevPayment.year,
        admission_fee: billData.data.admission_fee ?? prevPayment.admission_fee,
        admission_date:
          billData.data.admission_date ?? prevPayment.admission_date,
      }));
      setLevel(0);
    }
  }, [billData]);

  useEffect(() => {
    if (payment) {
      const updatedTotal =
        (payment.dues || 0) -
        (payment.deducted_amount || 0) +
        (payment.extra_charge || 0);

      setTotalPayable(updatedTotal);
    }
  }, [payment]);

  useEffect(() => {
    const upiAmount = pay.upi_amount ?? 0;
    const cashAmount = pay.cash_amount ?? 0;
    const cardAmount = pay.card_amount ?? 0;

    const updatedTotal = upiAmount + cashAmount + cardAmount;
    setTotalAmt(updatedTotal);
  }, [pay]);

  const handlePayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue =
      name === "upi_amount" || name === "cash_amount" || name === "card_amount"
        ? parseFloat(value) || 0
        : value;

    setPay((prevPay) => ({
      ...prevPay,
      [name]: parsedValue,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue =
      name === "extra_charge" || name === "deducted_amount"
        ? parseFloat(value) || 0
        : value;

    setPayment((prevPay) => ({
      ...prevPay,
      [name]: parsedValue,
    }));
  };

  const validatePayment = (
    updatedState: TPaymentPayload,
    totalAmount: number,
    total_payable: number
  ): boolean => {
    // Check if received_by is null or an empty string
    if (updatedState.received_by === null || updatedState.received_by === "") {
      showNotification("error", "Please select an InCharge Employee");
      return false;
    }

    if (totalAmount > total_payable) {
      showNotification(
        "error",
        "Total payment amount cannot exceed the Payable Amount"
      );
      return false;
    }

    if (
      updatedState.upi_amount === 0 &&
      updatedState.cash_amount === 0 &&
      updatedState.card_amount === 0
    ) {
      showNotification("error", "Please check the entered amounts");
      return false;
    }

    return true;
  };

  const handleEditBillSubmit = () => {
    const { id, extra_charge, deducted_amount, remarks } = payment ?? {};

    if (
      id == null ||
      isNaN(extra_charge ?? NaN) ||
      isNaN(deducted_amount ?? NaN) ||
      remarks == null
    ) {
      console.error(
        "Validation failed: One or more values are missing or invalid."
      );
      return;
    }

    const updatedState = {
      id,
      extra_charge: extra_charge ?? 0,
      deducted_amount: deducted_amount ?? 0,
      remarks,
    };

    updateBill({ id, body: updatedState });
  };

  const handlePaymentSubmit = () => {
    const updatedState = {
      bill: payment.id,
      patient: payment.patient,
      admission: payment.admission,
      received_by: pay.received_by
        ? parseInt(pay.received_by.toString(), 10)
        : null,
      remark: pay.remark,
      month: payment.month,
      year: payment.year,
      upi_amount: pay.upi_amount ?? 0,
      cash_amount: pay.cash_amount ?? 0,
      card_amount: pay.card_amount ?? 0,
    };
    const total_payable =
      (payment.dues || 0) +
      (payment.extra_charge || 0) -
      (payment.deducted_amount || 0);

    const totalAmount =
      updatedState.upi_amount +
      updatedState.cash_amount +
      updatedState.card_amount;

    if (validatePayment(updatedState, totalAmount, total_payable)) {
      addPayment(updatedState);
    }
  };

  const handleCancel = () => {
    onClose();
    setPayment((prevPayment) => ({
      id: null,
      patient: null,
      admission: null,
      patient_name: "",
      address: "",
      organisation: 0,
      created_on: "",
      dues: prevPayment.dues, // Keep dues unchanged
      deducted_amount: 0,
      extra_charge: 0,
      remarks: "",
      paid_amount: 0,
      month: null,
      year: null,
      admission_fee: 0,
      admission_date: "",
    }));
    setEnableEdit(true);
    setLevel(0);
    setPay({
      id: null,
      bill: null,
      patient: null,
      admission: null,
      received_by: null,
      remark: "",
      month: null,
      year: null,
      upi_amount: 0,
      cash_amount: 0,
      card_amount: 0,
    });
  };

  const handleEnableEdit = () => {
    setEnableEdit((prev) => !prev);
  };

  const handleProceed = () => {
    setLevel(1);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setPay((prevPay) => ({
      ...prevPay,
      received_by: parseInt(newValue as unknown as string, 10) || null,
    }));
  };

  return (
    <>
      {isLoading ? null : (
        <Modal
          open={open}
          onClose={onClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={style}>
            <div className="bg-[#1C1C1C] sticky top-0 z-30 flex justify-between items-center px-4">
              <DialogTitle
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Add Payment
              </DialogTitle>
              <IconButton onClick={onClose} sx={{ color: "white" }}>
                <IoCloseCircleOutline />
              </IconButton>
            </div>
            {level === 0 && (
              <>
                <DialogContent>
                  <div className=" grid grid-cols-2 gap-5">
                    <TextField
                      label="Dues"
                      value={payment?.dues}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      label="Payable Amount"
                      value={totalPayable || 0}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      label="Discount"
                      value={payment?.deducted_amount || ""}
                      variant="outlined"
                      name="deducted_amount"
                      onChange={handleChange}
                      disabled={enableEdit}
                    />
                    <TextField
                      label="Extra Charge"
                      value={payment?.extra_charge || ""}
                      variant="outlined"
                      name="extra_charge"
                      onChange={handleChange}
                      disabled={enableEdit}
                    />

                    <TextField
                      label="Month"
                      value={payment?.month}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      label="Year"
                      value={payment?.year}
                      variant="outlined"
                      disabled
                    />

                    <TextField
                      label="Remarks"
                      name="remarks"
                      value={payment?.remarks || ""}
                      variant="outlined"
                      onChange={handleChange}
                      multiline
                      maxRows={4}
                      className="col-span-2"
                      disabled={enableEdit}
                    />
                  </div>
                </DialogContent>
                <div className="flex justify-center mb-4 items-end flex-grow">
                  <DialogActions>
                    <section className=" flex justify-center items-center space-x-6">
                      <div className={`${!enableEdit ? "hidden" : "block"}`}>
                        <button
                          onClick={handleProceed}
                          className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                        >
                          <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                          <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                          <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                            Proceed to Payment
                          </span>
                          <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                        </button>
                      </div>
                      <div className={`${enableEdit ? "hidden" : "block"}`}>
                        <button
                          onClick={handleEditBillSubmit}
                          className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                        >
                          <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                          <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                          <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                            Save
                          </span>
                          <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                        </button>
                      </div>

                      <div className="">
                        <button
                          onClick={handleEnableEdit}
                          className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                        >
                          <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                          <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                          <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                            {enableEdit ? "Update Bill" : "Cancel"}
                          </span>
                          <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                        </button>
                      </div>
                      <div className="">
                        <button
                          onClick={handleCancel}
                          className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                        >
                          <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                          <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                          <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                            Cancel Payment
                          </span>
                          <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                        </button>
                      </div>
                    </section>
                  </DialogActions>
                </div>
              </>
            )}
            {level === 1 && (
              <>
                <DialogContent>
                  <div className="grid grid-cols-2 gap-5">
                    <div className=" col-span-2">
                      <TextField
                        label="Payable Amount"
                        value={totalPayable || 0}
                        variant="outlined"
                        disabled
                      />
                    </div>
                    <TextField
                      label="UPI Amount"
                      value={pay?.upi_amount || 0}
                      variant="outlined"
                      name="upi_amount"
                      onChange={handlePayChange}
                    />

                    <TextField
                      label="Cash Amount"
                      value={pay?.cash_amount || 0}
                      variant="outlined"
                      name="cash_amount"
                      onChange={handlePayChange}
                    />

                    <TextField
                      label="Card Amount"
                      value={pay?.card_amount || 0}
                      variant="outlined"
                      name="card_amount"
                      onChange={handlePayChange}
                    />

                    <TextField
                      label="Total Amount"
                      value={totalAmt || 0}
                      variant="outlined"
                      disabled
                    />

                    <TextField
                      label="Remarks"
                      name="remark"
                      value={pay?.remark || ""}
                      variant="outlined"
                      onChange={handlePayChange}
                      multiline
                      maxRows={4}
                      className="col-span-2"
                    />
                    <FormControl
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      required
                    >
                      <InputLabel
                        style={{
                          color: "gray",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                      >
                        Select In Charge
                      </InputLabel>
                      <Select
                        name="received_by"
                        value={String(pay?.received_by) || ""}
                        onChange={handleSelectChange}
                        label="Select In Charge"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "1.5rem",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "inherit",
                            borderRadius: "1.5rem",
                          },
                          "& .MuiInputBase-input": {
                            height: "1rem",
                            padding: "1rem 1rem",
                            textTransform: "capitalize",
                          },
                          "& .MuiSelect-icon": {
                            color: "gray",
                          },
                        }}
                      >
                        {employeeData?.list?.map((employee: EmpRow) => (
                          <MenuItem
                            key={employee.id}
                            value={String(employee.id)}
                          >
                            {employee.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </DialogContent>
                <div className="flex justify-center mb-4 items-end flex-grow">
                  <DialogActions>
                    <section className=" flex justify-center items-center space-x-6">
                      {level === 1 && (
                        <div className={`${!enableEdit ? "hidden" : "block"}`}>
                          <button
                            onClick={handlePaymentSubmit}
                            className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                          >
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                              Pay
                            </span>
                            <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                          </button>
                        </div>
                      )}

                      <div className="">
                        <button
                          onClick={handleCancel}
                          className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                        >
                          <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                          <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                          <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                            Cancel Payment
                          </span>
                          <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                        </button>
                      </div>
                    </section>
                  </DialogActions>
                </div>
              </>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AddPayment;
